<template>
  <div class="page-wrap px-2.5 py-3.5">
    <!-- 筛选栏 -->
    <div class="nav flex mt-0.5 px-2.5 py-2 bg-white" style="padding-right:0">
      <div class="felx items-center mb-2 text-sm font-medium">
        <span class="text-red-500">*</span>
        <span>所属区域</span>
      </div>
      <div class="screen-wrap" @click="screen_show=true">
        <div class="flex-1 van-ellipsis">{{ screen_region.areaName }}</div>
        <van-icon name="arrow-down" color="#666" size="12" />
      </div>
    </div>
    <div class="felx items-center mb-2 text-sm font-medium" style="margin-top: 70px">
      <span class="text-red-500">*</span>
      <span>城市管理</span>
    </div>
    <div class="flex items-center mb-5 px-3 py-3 rounded bg-white">
      <input type="text" v-model="cityName" placeholder="请输入城市名称" class="felx-1 text-sm">
    </div>
    <!-- <div class="felx items-center mb-2 text-sm font-medium">
      <span>启用状态</span>
    </div>
    <div class="flex items-center mb-5 px-3 py-3 rounded bg-white" @click="check_status=true">
      <div class="text-sm flex-1 text-gray-600">{{ status_active }}</div>
      <van-icon name="arrow" size="13" />
    </div> -->

    <div class="submit-btn" @click="Click">提交</div>
    <!-- 筛选弹窗 -->
    <van-action-sheet v-model="screen_show" position="bottom">
      <van-picker
        title="选择区域"
        show-toolbar
        :columns="region_list"
        value-key="areaName"
        @confirm="screenConfirm"
        @cancel="screen_show=false"
      />
    </van-action-sheet>
    <van-action-sheet v-model="check_status">
      <van-picker
        title="选择启用状态"
        show-toolbar
        :columns="status_list"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
      />

    </van-action-sheet>
  </div>
</template>
<script>
import { Icon, ActionSheet, Picker } from 'vant'
import { addcity, putCity, areaList } from '../../../api'
export default {
  name:'',
  components:{
    [Icon.name]:Icon,
    [ActionSheet.name]:ActionSheet,
    [Picker.name]:Picker
  },
  data() {
    return {
      region_list: [{ id: '', areaName: "区域" }],
      // 当前选择的区域
      screen_region: { id: '', areaName: "区域" },
      // 打开筛选弹窗
      screen_show: false,
      check_status:false,
      status_active:'选中后的名称样式',
      status_list:['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州'],
      cityName: '',
      id: ''
    }
  },
  created() {
    
    this.areaList()
  },
  methods:{
    screenConfirm(val) {
      this.screen_region = val
      this.screen_show = false
    },
    //区域列表
    areaList() {
      this.$toast.loading({
        forbidClick: true,
        duration: 0
      })
      areaList({}).then(res=>{
        var data = res.data.data
        if (this.$route.query.id) {
          this.id=this.$route.query.id,
          
          this.cityName = this.$route.query.cityName
          for (var item of data) {
            if (item.id==this.$route.query.areaId) {
              this.screen_region = {
                id: this.$route.query.areaId,
                areaName: item.areaName
              }
              break
            }
          }
        }
        this.region_list = data
      }).finally(()=>{
        this.$toast.clear()
      })
    },
    onConfirm(value, index) {
      console.log(`当前值：${value}, 当前索引：${index}`)
      this.status_active = value
      this.check_status = false
    },
    onChange(picker, value, index) {
      console.log(`当前值：${value}, 当前索引：${index}`)
    },
    onCancel() {
      console.log('取消')
      this.check_status = false
    },
    Click() {
      if (this.screen_region.id=='') {
        return this.$toast('请选择区域')
      }
      if (this.cityName=='') {
        return this.$toast('请输入城市')
      }
      this.$toast.loading({
          message: '',
          forbidClick: true,
          duration: 0
      });
      if (this.$route.query.id) {
        this.putCity()
      } else {
        this.addcity()
      }
    },
    //新增
    addcity() {
      addcity({
        areaId: this.screen_region.id,
        cityName: this.cityName,
      }).then(res=>{
        this.$toast.success('新增成功');
        setTimeout(()=>{
          this.$router.go(-1)
        }, 1500)
      }).catch(()=>{
        this.$toast.clear()
      })
    },
    //修改
    putCity () {
      putCity(this.id, {
        areaId: this.screen_region.id,
        cityName: this.cityName,
        id: this.id
      }).then(res=>{
        this.$toast.success('修改成功');
        setTimeout(()=>{
          this.$router.go(-1)
        }, 1500)
      }).catch(()=>{
        this.$toast.clear()
      })
    }
  }
}
</script>
<style scoped>
  .page-wrap{
    background-color: #f6f6f6;
    min-height:100vh;
  }
  .nav{
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    width: 100%;
    z-index: 10;
    margin-top: 0;
  }
</style>
